import { render, staticRenderFns } from "./UndeliveredParcelTenAndThirtyDays.vue?vue&type=template&id=1a63d8f4&scoped=true"
import script from "./UndeliveredParcelTenAndThirtyDays.vue?vue&type=script&lang=js"
export * from "./UndeliveredParcelTenAndThirtyDays.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a63d8f4",
  null
  
)

export default component.exports