<template>
  <div>
    <div class="tabs container card-tabs">
      <ul>
        <li v-for="(tab, index) in tabs" :key="index" :class="{ 'is-active': activeTab === index }">
          <a style="font-weight: 500" @click="changeTab(index)">{{ tab.title }}
            <slot name="tab-title" :count="tab.count"></slot>
          </a>
        </li>
      </ul>
      <vs-button @click="exportShipmentOrdersToExcel" success style="border-radius: 4px !important;"
                 :loading="isExporting">
        Export Excel
      </vs-button>
    </div>
    <div class="tab-content">
      <keep-alive>
        <component @dataCountUpdated="updateDataCount"
                   :key="currentTab.nameKey"
                   ref="componentRender" :is="activeComponent" :due_days="currentTab.value"/>
      </keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 0,
      isExporting: false,
    };
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    currentTab() {
      return this.tabs[this.activeTab];
    },
    activeComponent() {
      return this.currentTab.component;
    },
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
    },
    updateDataCount(dataCount) {
      this.tabs[this.activeTab].count = dataCount.count || 0;
    },
    exportShipmentOrdersToExcel() {
      if(this.isExporting) {
        return;
      }

      this.isExporting = true;
      /// Preparing data component
      const selectedComponent = this.tabs[this.activeTab].component;
      const isReturnedShipment = selectedComponent.name === "WaitingForParcels";
      const selectedRenderedComponent = this.$refs.componentRender;

      let body = {};
      if(isReturnedShipment) {
        body = {
          is_only_count: "false",
          force_id: "true",
          province_id: selectedRenderedComponent.selectedProvince ? selectedRenderedComponent.selectedProvince
              : selectedRenderedComponent.defaultProvince,
          query: selectedRenderedComponent.searchItem,
          start_date: "",
          end_date: "",
          limit: 10
        };
      } else {
        body = {
          is_only_count: "false",
          force_id: "true",
          province_id: selectedRenderedComponent.selectedProvince ? selectedRenderedComponent.selectedProvince
              : selectedRenderedComponent.defaultProvince,
          query: selectedRenderedComponent.searchItem,
          due_days: selectedRenderedComponent.due_days,
          limit: 10,
        };
      }

      this.$store.dispatch('exportReturningReturnedShipmentToExcel', {
        httpClient: this.$http,
        returned: isReturnedShipment,
        body: body
      }).catch(() => {
      }).finally(() => {
        this.isExporting = false;
      })
    },
  },
};
</script>

<style scoped lang="scss">
/* Add any custom styles here */
.card-tabs {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;

  ul {
    margin: 0;
  }
}
</style>
