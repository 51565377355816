<script>
import pagination from "@/Plugins/Paginations/CusorPagination";
import loading from "@views/Loading.vue";
import moment from "moment";
import * as helper from "@/Helpers/GetValue";
import DatePicker from "vue2-datepicker";

export default {
  name: 'DeliveredParcelsWithDueDays',
  components: {
    DatePicker,
    pagination,
    loading
  },
  props: {
    due_days: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      searchItem: '',
      // Pagination
      page: 1,
      max: 10,
      offset: 10,
      count: 0,
      pagination: {},
      per_page: 10,
      limit_page: 10,

      parcelCheckingReturn: [],
      parts: [],
      defaultParts: 2,
      defaultProvince: 10,
      selectedPart: '',

      translateInloas: {
        center: 'ພາກກາງ',
        south: 'ພາກໃຕ້',
        north: 'ພາກເໜືອ',
        northeastern: 'ພາກຕາເວັນອອກສຽງເໜືອ',
        southwest: 'ຕາເວັນຕົກສຽງໃຕ້',
        southern: 'ພາກໃຕ້-1'
      },
      isLoading: true,

      provinces: [],
      selectedProvince: '',

      filterProvinces: [],

      startDate: null,
      endDate: null,
    }
  },
  methods: {
    disabledAfterDate(date) {
      let todayDate = moment(new Date());
      let maxDays = this.due_days + 1;
      const maxDate = this.due_days > 10 ? todayDate.subtract(maxDays, "days") : todayDate.subtract(maxDays, "days");
      maxDate.utcOffset(0);
      maxDate.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      return date > maxDate;
    },
    FilterData() {
      helper.GetOldValueOnInput(this);
    },
    FetchAddresses() {
      this.$axios.get('list-branch-address').then(res => {
        const items = res.data.data;

        this.parts = items.parts;
        this.selectedPart = 2;
        this.provinces = items.provinces;
        this.selectedProvince = 10;

        this.filterProvinces = items.provinces;
      }).catch(() => {
      });
    },
    Filter_By_Part(partId) {
      this.isLoading = true;
      this.pagination.current_page = 1;
      this.selectedProvince = null;
      this.FilterProvince(partId);
    },
    // Filter Provinces
    FilterProvince(partId) {
      const result = this.filterProvinces.filter(item => {
        return item.part_id == partId;
      });
      this.provinces = JSON.parse(JSON.stringify(result));
      if (this.provinces.length) {
        this.selectedProvince = this.provinces[0].proId;
        this.FetchData();
      }
    },
    Filter_By_Province() {
      this.pagination.current_page = 1;
      this.FetchData();
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss")
    },
    FetchData() {
      this.isLoading = true;
      this.fetchItem().then((res) => {
        if (!res.data.error) {
          setTimeout(() => {
            this.parcelCheckingReturn = res.data.data;
            this.pagination = res.data;
          }, 300);
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
      /// Counting data
      this.countData()
    },
    countData() {
      this.fetchItem({isCount: true}).then((res) => {
        if (!res.data.error) {
          this.$emit('dataCountUpdated', {
            'count': res.data.count,
            type: 'due_days_inbound_count',
            due_days: this.due_days
          })
        }
      }).catch(() => {
      });
    },
    fetchItem(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('v1/auth/branches/shipments/orders/checking/shipment-returning', {
        params: {
          is_only_count: options.isCount ?? false,
          force_id: true,
          province_id: this.selectedProvince ? this.selectedProvince : this.defaultProvince,
          partId: this.selectedPart ? this.selectedPart : this.defaultParts,
          due_days: this.due_days,
          query: this.searchItem,
          start_date: this.startDate,
          end_date: this.endDate,
          limit: 10,
          cursor: current_page ?? this.pagination.cursor,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    setDefaultDates() {
      let maxDays = this.due_days + 1;
      this.startDate = moment(new Date()).subtract(5, "months").format('YYYY-MM-DD');
      const endDateCurrent = moment(new Date());
      const maxDate = this.due_days > 10 ? endDateCurrent.subtract(maxDays, "days")
          : endDateCurrent.subtract(maxDays, "days");
      this.endDate = maxDate.format('YYYY-MM-DD');
    }
  },
  watch: {
    'due_days': function (value) {
      if (value) {
        this.FetchData();
      }
    },
    'searchItem': function (value) {
      if (value == '') {
        this.FetchData();
      }
    },
    'startDate': function () {
      this.FetchData();
    },
    'endDate': function () {
      this.FetchData();
    },
  },
  created() {
    this.FetchAddresses()
    this.FetchData();
    this.setDefaultDates();
  }
}
</script>

<template>
  <div class="container" style="margin-top: 0 !important;">
    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <div class="control">
            <label class="label">ຄົ້ນຫາ</label>
            <input
                @keyup.enter="FilterData()"
                v-model="searchItem"
                class="input"
                placeholder="ກົດ Enter ເພື່ອຄົ້ນຫາ..."
            >
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ຮອດສາຂາ ຈາກວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="startDate"
                :disabled-date="disabledAfterDate"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-model="endDate"
                :disabled-date="disabledAfterDate"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <div class="control">
            <label class="label">ເລືອກພາກ</label>
            <div class="select">
              <div class="select">
                <select class="input" v-model="selectedPart" @change="Filter_By_Part(selectedPart)">
                  <option v-for="(part, index) in parts" :key="index" :value="part.partId">
                    {{ translateInloas[part.name] }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ເລືອກເເຂວງ</label>
            <div class="select">
              <select class="input" v-model="selectedProvince" @change="Filter_By_Province">
                <option v-for="(province, index) in provinces" :key="index" :value="province.proId">
                  {{ province.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ລະຫັດພັດສະດຸ</vs-th>
            <vs-th>ສາຂາຕົ້ນທາງ / ສາຂາປາຍທາງ</vs-th>
            <vs-th>ຜູ້ຍິງພັດສະດຸ</vs-th>
            <vs-th>ວັນທີ່</vs-th>

          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="index" v-for="(item, index) in parcelCheckingReturn">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td style="font-weight: 900">
              {{ item.shipment.shipment_number }}
            </vs-td>
            <vs-td>
              {{ item.shipment.start_branch.name }}
              <span style="margin-left: 18px"> <i class="fal fa-long-arrow-right"></i></span>
              <span style="margin-left: 18px;"> {{ item.shipment.end_branch.name }}</span>
            </vs-td>
            <vs-td>
              {{ item.created_by_user.name }} ({{ item.created_by_user.user_tel }})
            </vs-td>
            <vs-td>
              {{ formatDate(item.created_at) }}
            </vs-td>

          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
