<template>
  <div>
    <TabMenu :tabs="tabsData">
      <template v-slot:tab-title="tab">
          <div class="badge-color">
               ({{tab.count}})
          </div>
      </template>
    </TabMenu>
  </div>
</template>

<script>
import TabMenu from "@views/SuperAdmin/CheckParcelArrived/TabMenu.vue";
import UndeliveredParcelTenAndThirtyDays
  from "@views/SuperAdmin/CheckParcelArrived/ParcelStatus/UndeliveredParcelTenAndThirtyDays.vue"
import BounceParcelWaitingOut from "@views/SuperAdmin/CheckParcelArrived/ParcelStatus/BounceParcelWaitingOut.vue";
import axios from 'axios';

export default {
  components: {
    TabMenu,
  },
  data() {
    return {
      tabsData: [
        {
          title: 'ເຄື່ອງຄ້າງສາງເກີນ 10 ວັນ',
          nameKey: 'UndeliveredParcelTenDays',
          component: UndeliveredParcelTenAndThirtyDays,
          value: 10,
          count: 0,
        },
        {
          title: 'ເຄື່ອງຄ້າງສາງເກີນ 30 ວັນ',
          nameKey: 'UndeliveredParcelThirtyDays',
          component: UndeliveredParcelTenAndThirtyDays,
          value: 30,
          count: 0,
        },
        {
          title: 'ຕີກັບລໍຖ້າຍິງອອກ',
          nameKey: 'BounceParcelWaitingOut',
          component: BounceParcelWaitingOut,
          count: 0,
        },
        // Add more tabs as needed
      ],
    };
  },
  methods: {
    fetchReturningShipmentsCount(dueDays) {
      return this.$http.get('v1/auth/branches/shipments/orders/checking/shipment-returning', {
        params: {
          is_only_count: "true",
          force_id: true,
          province_id: 10,
          due_days: dueDays,
          query: '',
          limit: 10,
          cursor:  null,
          per_page: 10,
        }
      })
    },
    fetchReturnedShipmentsCount() {
      return this.$http.get('v1/auth/branches/shipments/orders/checking/shipment-returned', {
        params: {
          is_only_count: "true",
          force_id: true,
          province_id: 10,
          query: '',
          start_date: "",
          end_date: "",
          limit: 10,
          cursor: null,
          per_page: 10,
        }
      })
    },
    fetchTabsCount() {
      axios.all([
        this.fetchReturningShipmentsCount(10),
        this.fetchReturningShipmentsCount(30),
        this.fetchReturnedShipmentsCount()
      ]).then((responses) => {
        responses.forEach((response, key) => {
          this.tabsData[key].count = response.data.count
        })
      }).catch((errors) => {
        console.log("fetchTabsCount::errors=>", errors)
      })
    }
  },
  created() {
    this.fetchTabsCount()
  }
};
</script>
<style lang="scss">
.badge-color {
  font-size: 24px;
  font-weight: 900;
  margin-left: 12px;
  color: red;
}
</style>
